const { getAccessTokenFromCookie } = require("@/auth/utils");
const axios = require("axios");
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return error.data
  }
);
module.exports = {
  connectApi: async (url) => {
    try{
      const token = getAccessTokenFromCookie()[0];
      // const apiUrl = url.replace('https://scx.clicklab.app/', 'https://api.appsmanager.co/');
      const apiUrl = url.replace('scx',`sc${getAccessTokenFromCookie()[1].server}`);
      const params = `?tokenV2=${token}`;;
      const headers = {
        Authorization: token,
      };
      const response = await axios.get(apiUrl, { headers });
      return response;
    } catch (error) {
      console.error('Error connecting to the API:', error);
    }
  },
};
