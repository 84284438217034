<template>
    <b-card v-if="totalScore && IsPro">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <h3>{{ totalScore }} / 10</h3>
          <p>
            Traffic Score
            <!-- <span v-if="totalScore >= 0 && totalScore < 2.5">easy</span>
            <span v-if="totalScore >= 2.5 && totalScore < 5">meduim</span>
            <span v-if="totalScore >= 5 && totalScore < 7.5">hard</span>
            <span v-if="totalScore >= 7.5 && totalScore < 10">super hard</span> -->
          </p>
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <div class="progress-img" style="position: relative">
            <img
              v-if="totalScore >= 0 && totalScore < 2.5"
              :src="prgressBarCircle1"
            />
            <img
              v-if="totalScore >= 2.5 && totalScore < 5"
              :src="prgressBarCircle2"
            />
            <img
              v-if="totalScore >= 5 && totalScore < 7.5"
              :src="prgressBarCircle3"
            />
            <img
              v-if="totalScore >= 7.5 && totalScore < 10"
              :src="prgressBarCircle4"
            />
          </div>
        </div>
      </div>
    </b-card>
    <b-card no-body v-else-if="totalScore && !IsPro" class="diffScore p-1">
      <div class="d-flex align-items-center justify-content-between">
      <!-- <div class="d-flex align-items-center justify-content-between" style="filter: blur(3px);"> -->
        <div>
          <h3><feather-icon icon="LockIcon" size="20" /> / 10</h3>
          <p class="p-0 m-0">
            Traffic Score
            <!-- <span v-if="totalScoreF >= 0 && totalScoreF < 2.5">easy</span>
            <span v-if="totalScoreF >= 2.5 && totalScoreF < 5">meduim</span>
            <span v-if="totalScoreF >= 5 && totalScoreF < 7.5">hard</span>
            <span v-if="totalScoreF >= 7.5 && totalScoreF < 10">super hard</span
            > -->
          </p>
          <b-button style="margin-top:5px;padding: 3px;" variant="outline-secondary" to="/pages/plan-selection">
            Upgrade Now
          </b-button> 
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <div class="progress-img" style="position: relative">
            <feather-icon icon="LockIcon" class="bg-light-secondary rounded-circle" style="padding: 15px"
            size="60" />
            <!-- <img
              v-if="totalScoreF >= 0 && totalScoreF < 2.5"
              :src="prgressBarCircle1"
            />
            <img
              v-if="totalScoreF >= 2.5 && totalScoreF < 5"
              :src="prgressBarCircle2"
            />
            <img
              v-if="totalScoreF >= 5 && totalScoreF < 7.5"
              :src="prgressBarCircle3"
            />
            <img
              v-if="totalScoreF >= 7.5 && totalScoreF < 10"
              :src="prgressBarCircle4"
            /> -->
          </div>
        </div>
      </div>
      <!-- <div class="upgrades">
        <feather-icon icon="LockIcon" size="30" class="text-secondary" /> 
        <span>Traffic Score</span>
                  <b-button style="margin-top: 10px;padding: 6px;" variant="outline-secondary" to="/pages/plan-selection">
            Upgrade Now
          </b-button> 
      </div> -->
    </b-card>
</template>
<script>
import {
  BContainer,
  BRow,
  BCol,
  BButton,
  BCard,
  BForm,
  BSpinner,
  BCardText,
  BFormFile,
  BFormInput,
  BFormGroup,
  BFormSelect,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { connectApi } from "@/helper/connectApi";
import { estimatedFinalScore } from "@/helper/traffic";
import axios from '@axios';
import prgressBarCircle1 from '@/assets/images/25-1.svg';
import prgressBarCircle2 from '@/assets/images/50-1.svg';
import prgressBarCircle3 from '@/assets/images/75-1.svg';
import prgressBarCircle4 from '@/assets/images/100-1.svg';
export default {
  data() {
    return {
      apps: [],
      isLoading: false,
      totalScore: null,
      IsPro: false,
      prgressBarCircle1,
      prgressBarCircle2,
      prgressBarCircle3,
      prgressBarCircle4,
    };
  },
  components: {
    BButton,
    BContainer,
    BRow,
    BCol,
    BCard,
    BForm,
    BCardText,
    BSpinner,
    BFormFile,
    BFormInput,
    BFormGroup,
    BFormSelect,
    "b-tooltip": VBTooltip,
  },
  props: {
    dataTraffic: {
      type: String,
      required: true,
    },
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  async mounted() {
    this.checkUserPlan();
  },
  methods: {
    async checkUserPlan() {
      axios
        .post("/checkUserSubscription")
        .then(async ({ data }) => {
          this.IsPro = true;
          this.totalScore = null;
          await this.getApps();
          const d = await estimatedFinalScore(
            this.dataTraffic,
            this.apps,
            "0"
          );
          this.totalScore = d.score;
          
          this.$emit("ready2", true);
        })
        .catch((err) => {
          this.IsPro = false;
          this.totalScore = 7.6;
          this.$emit("ready2", true);
        });
    },
    async getApps() {
      this.isLoading = true;
      const response = await connectApi(
        `https://scx.clicklab.app/api/apps/?q=${this.data}&fullDetail=true`
      );
      this.apps = response.data.results;
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.progress-img {
  position: relative;
}

.progress-img img {
  height: 60px;
  width: 60px;
}

.progress-img .p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.diffScore {
  position: relative;
}
.upgrades {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  background: black;
  opacity: 0.7;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>