<template>
  <!-- class="d-flex flex-wrap align-items-center justify-content-between" -->
  <b-row style="margin-top:15px" cols="12" class="mx-0">
    <b-col sm="12" md="4" class="row-col " v-if="showNow && from !== 'app'">
      <b-card v-if="showNow && from !== 'app'">
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <h3>
              {{ itemsLength }}
            </h3>
            <p>
              {{ appCountText }}
            </p>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <feather-icon
              class="text-white bg-light-info rounded-circle"
              style="padding: 15px"
              icon="MenuIcon"
              size="60"
            />
          </div>
        </div>
      </b-card>
      <b-card v-else-if="!showNow && from !== 'app'">
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <h3>
              <b-skeleton width="100px" height="30px" class="mb-2"></b-skeleton>
            </h3>
            <p>
              <b-skeleton width="80px" height="20px"></b-skeleton>
            </p>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <b-skeleton width="60px" height="60px"></b-skeleton>
          </div>
        </div>
      </b-card>
    </b-col>

    <b-col  class="row-col mt-1 mt-md-0 mt-lg-0 mt-xl-0" sm="12" :md="from === 'app' ? 6 : 4" :lg="from === 'app' ? 3 : 4">
      <b-card v-if="showNow1">
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <h3>
              {{ totalInstall }}
            </h3>
            <p>
              {{ downloadText }}
            </p>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <feather-icon
              icon="DownloadIcon"
              class="text-white bg-light-success rounded-circle"
              style="padding: 15px"
              size="60"
            />
          </div>
        </div>
      </b-card>
      <b-card v-else >
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <h3>
              <b-skeleton width="100px" height="30px" class="mb-2"></b-skeleton>
            </h3>
            <p>
              <b-skeleton width="80px" height="20px"></b-skeleton>
            </p>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <b-skeleton width="60px" height="60px"></b-skeleton>
          </div>
        </div>
      </b-card>
    </b-col>

    <b-col class="row-col mt-1 mt-md-0 mt-lg-0 mt-xl-0" sm="12" :md="from === 'app' ? 6 : 4" :lg="from === 'app' ? 3 : 4">
      <b-card v-if="showNow2">
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <h3>
              {{ totalSuggests }}
            </h3>
            <p>
              {{ keywordSuggestsText }}
            </p>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <feather-icon
              :icon="dIcon"
              class="text-white bg-light-warning rounded-circle"
              :class="
                dIcon === 'StarIcon'
                  ? 'text-warning feather feather-star stroke-current fill-current h-6 w-6'
                  : ''
              "
              style="padding: 15px"
              size="60"
            />
          </div>
        </div>
      </b-card>
      <b-card v-else>
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <h3>
              <b-skeleton width="100px" height="30px" class="mb-2"></b-skeleton>
            </h3>
            <p>
              <b-skeleton width="80px" height="20px"></b-skeleton>
            </p>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <b-skeleton width="60px" height="60px"></b-skeleton>
          </div>
        </div>
      </b-card>
    </b-col>
    <b-col class="row-col mt-1 mt-md-1 mt-lg-0 mt-xl-0" v-if="from === 'app'" sm="12" :md="from === 'app' ? 6 : 4"  :lg="from === 'app' ? 3 : 4">
      <difficulty-score
        v-if="from === 'app'"
        :keywordSearch="data"
        :ready="ready"
        @ready="onReadyEvent"
      />

      <b-card v-if="!showNow3 && from === 'app'" >
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <h3>
              <b-skeleton width="100px" height="30px" class="mb-2"></b-skeleton>
            </h3>
            <p>
              <b-skeleton width="80px" height="20px"></b-skeleton>
            </p>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <b-skeleton width="60px" height="60px"></b-skeleton>
          </div>
        </div>
      </b-card>
    </b-col>
    <b-col class="row-col mt-1 mt-md-1 mt-lg-0 mt-xl-0" v-if="from === 'app'" sm="12" :md="from === 'app' ? 6 : 4" :lg="from === 'app' ? 3 : 4">
      <traffic-score
        v-if="from === 'app'"
        :dataTraffic="data"
        :ready="ready2"
        @ready="onReadyEvent2"
      />
      <b-card v-if="!showNow3 && from === 'app'">
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <h3>
              <b-skeleton width="100px" height="30px" class="mb-2"></b-skeleton>
            </h3>
            <p>
              <b-skeleton width="80px" height="20px"></b-skeleton>
            </p>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <b-skeleton width="60px" height="60px"></b-skeleton>
          </div>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard, BCardText, BSkeleton } from "bootstrap-vue";
import DifficultyScore from "@/components/market-manager/DifficultyScore.vue";
import TrafficScore from "@/components/market-manager/TrafficScore.vue";

export default {
  name: "AnalyticsCard",
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BSkeleton,
    "difficulty-score": DifficultyScore,
    TrafficScore,
  },
  data() {
    return {
      ready: false,
      ready2: false,
      showNow: false,
      showNow1: false,
      showNow2: false,
      showNow3: false,
      showNow4: false,
    };
  },
  props: {
    from: {
      type: String,
      required: true,
    },
    data: {
      type: String,
    },
    itemsLength: {
      type: String,
      required: true,
    },
    totalInstall: {
      type: String,
      required: true,
    },
    totalSuggests: {
      type: String,
      required: true,
    },
    dIcon: {
      type: String,
      default: "ActivityIcon",
    },
    appCountText: {
      type: String,
      default: "App count",
    },
    downloadText: {
      type: String,
      default: "Downloads",
    },
    keywordSuggestsText: {
      type: String,
      default: "Keyword Suggests",
    },
  },
  methods: {
    onReadyEvent(isReady) {
      if (this.from !== "app") {
        this.ready = true;
      } else {
        this.ready = isReady;
      }
    },
    onReadyEvent2(isReady) {
      if (this.from !== "app") {
        this.ready = true;
      } else {
        this.ready2 = isReady;
      }
    },
  },
  watch: {
    ready: {
      handler(newValue, oldValue) {
        if (this.from !== "app") {
          newValue = true;
        }
        this.showNow = newValue;
        this.showNow1 = newValue;
        this.showNow2 = newValue;
        this.showNow3 = newValue;
        this.showNow4 = newValue;
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
.row-col {
  padding: 0px 3px;
}
.card {
  margin-bottom: 0px;
}
</style>