
const R = require('ramda');
const axios = require('axios');
const {connectApi} = require('./connectApi')
function round(val) {
    return Math.round(val * 100) / 100;
}

function score(min, max, value) {
    value = Math.min(max, value);

    value = Math.max(min, value);
    return round(1 + (9 * (value - min)) / (max - min));
}

function zScore(max, value) {
    return score(0, max, value);
}

function iScore(min, max, value) {
    value = Math.min(max, value);
    value = Math.max(min, value);
    return round(1 + (9 * (max - value)) / (max - min));
}

function izScore(max, value) {
    return iScore(0, max, value);
}

function aggregate(weights, values) {
    const max = 10 * weights.reduce((acc, weight) => acc + weight, 0);
    const min = weights.reduce((acc, weight) => acc + weight, 0);
    const sum = weights.reduce((acc, weight, index) => acc + weight * values[index], 0);

    return score(min, max, sum);
}
aggregate([8,2,1],[3,2,4])
aggregate([5,3,2],[3,2,4])
function getKeywordLength(keyword) {
    const length = keyword.length;
    return {
        length,
        score: iScore(1, 30, length),
    };
}
const cache = new Map();
async function getSuggestions(term, keyword,server) {

    const cacheKey = `${term.toLowerCase()}-${keyword.toLowerCase()}`;

    // Check if the result is cached
    if (cache.has(cacheKey)) {
        const cachedResult = cache.get(cacheKey);
        return cachedResult;
    }

    try {
        const response = await  connectApi(`https://scx.clicklab.app/api/apps/?suggest=${term}`)
        const data = response.data;
        const listSuggestions = data.results;

        const foundIndex = listSuggestions.findIndex(
            (suggestion) => suggestion.term.toLowerCase() === keyword.toLowerCase()
        );

        if (foundIndex !== -1) {
            const result = { exists: true, length: term.length, position: foundIndex + 1 };
            // Cache the result
            cache.set(cacheKey, result);
            return result;
        } else {
            const result = { exists: false };
            // Cache the result
            cache.set(cacheKey, result);
            return result;
        }
    } catch (error) {
        console.error("An error occurred:", error);
        const result = { exists: false };
        // Cache the result
        cache.set(cacheKey, result);
        return result;
    }
}

async function getSuggestScore(keyword,server) {
    const terms = keyword.split("");
    const search = keyword;
    let currentTerm = "";
    let suggestScore = 0; // Initialize suggestScore

    try {
        for (const term of terms) {
            currentTerm += term;
            const result = await getSuggestions(currentTerm, search,server);

            if (result.exists) {
                const lengthScore = iScore(1, 30, result.length);
                const indexScore = izScore(4, result.position);
                suggestScore = aggregate([10, 1], [lengthScore, indexScore]);
                suggestScore = R.assoc('score', suggestScore, result);
                break; // Exit the loop if no suggestion is found
            } else {
                suggestScore = R.assoc('score', 0, result);
            }
        }
    } catch (error) {
        console.error("An error occurred:", error);
    }
    return suggestScore;
}




function getInstallsScore(apps) {
    const avg = R.sum(R.pluck("minInstalls", apps)) / apps.length;
    const max = 1000000;
    const score = zScore(max, avg);
    return { avg, score };
}

function getScore(suggestScore, lengthScore, installsScore) {
    const SUGGEST_W = 8;
    const INSTALLS_W = 2;
    const LENGTH_W = 1;
    const totalScore = aggregate(
        [SUGGEST_W, LENGTH_W, INSTALLS_W],
        [suggestScore.score, lengthScore.score, installsScore.score]
    );
    return totalScore;
}

function getTopApps(apps) {
    const top = apps.slice(0, 10);

    if (apps.length && !apps[0].description) {
        return Promise.all(top.map((app) => app.appId));
    } else {
        return Promise.resolve(top);
    }
}

// async function finalScore(keyword,apps) {
//     let totalScore = null;
//     let suggestScore = null;
//     let installsScore = null;
//     let lengthScore = null;
//     try {
//         [suggestScore, installsScore, lengthScore] = await Promise.all([
//             getSuggestScore(keyword, 30),
//             getInstallsScore(apps),
//             getKeywordLength(keyword, 30)
//         ]);

//         totalScore = getScore(suggestScore, lengthScore, installsScore);
//     } catch (error) {
//         console.error("An error occurred:", error);
//     }

//     return {
//         position : suggestScore.position,
//         length : suggestScore.length,
//         score: totalScore,
//         keyword: keyword,
//         lengthKeyword : keyword.length,

//     }
// }

async function estimatedFinalScore(keyword,apps,server) {
    let totalScore = null;
    let suggestScore = null;
    let installsScore = null;
    let lengthScore = null;
    let lengthKeyword = keyword.length
    try {
        [suggestScore, installsScore, lengthScore] = await Promise.all([
            lengthKeyword <= 30 ? getSuggestScore(keyword,server) : { score : 3.5,length:0,position:0 } ,
            getInstallsScore(apps),
            getKeywordLength(keyword, 30)
        ]);

        totalScore = getScore(suggestScore, lengthScore, installsScore);
    } catch (error) {
        console.error("An error occurred:", error);
    }
    return {
        position : suggestScore.position,
        length : suggestScore.length,
        score: totalScore,
        keyword: keyword,
        lengthKeyword : keyword.length,
        suggestScore: suggestScore.score,
        lengthScore: lengthScore.score,
        installsScore: installsScore.score
    }
    // return totalScore;
}

module.exports = {
    round,
    score,
    zScore,
    iScore,
    izScore,
    aggregate,
    getKeywordLength,
    getSuggestions,
    getInstallsScore,
    getScore,
    getTopApps,
    getSuggestScore,
    // finalScore,
    estimatedFinalScore,
    // Add more functions as needed
};

// finalScore('mexico med')
